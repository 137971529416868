
import Vue from 'vue'

export default Vue.extend({
  name: 'ProjektEntfernen',

  data: () => {
    return {
      dots: '.',
      interv: 0,
    }
  },

  computed: {
    id () {
      return this.$route.params.id
    },
    currentAward() {
      return this.$store.state.currentAward
    },
  },


  methods: {
    animateDots () {
      if (this.dots.length <= 4) {
        this.dots += '.'
      } else {
        this.dots = '.'
      }
    },
    deleteProject() {
      const yes = confirm("Sind Sie sich sicher?")
      if (yes) {
        this.interv = window.setInterval(this.animateDots, 200)
        this.$store
          .dispatch('deleteProject', this.id)
          .then(res => {
            console.log('deleteProject -> res', res)
            this.$router.push('/anmelden/index')
          });
        }
    },
    deleteProjectDetail() {
      const yes = confirm("Sind Sie sich sicher?")
      if (yes) {
        this.interv = window.setInterval(this.animateDots, 200)
        this.$store
          .dispatch('deleteProjectDetail', { 
            id: this.id, 
            year: this.currentAward.year, 
            awardType: this.currentAward.awardType 
          }).then(res => {
            console.log('deleteProjectDetail -> res', res)
            this.$router.push('/anmelden/index')
          });
      }
    },
  },

  mounted () {
  },

  destroyed () {
    clearInterval(this.interv)
  },

});
